import { CalendarDate, parseAbsolute, toZoned, ZonedDateTime } from '@internationalized/date';
// eslint-disable-next-line import/no-unresolved
import { RangeValue } from '@react-types/shared';
import { VariantProps } from 'class-variance-authority';
import { isEmpty, isFunction } from 'lodash-es';
import type { DatePickerProps, DateValue } from 'react-aria-components';
import { DatePicker, Dialog, Group } from 'react-aria-components';
import { DateInput } from '../../atoms/date-input/date-input';
import { DateSegment } from '../../atoms/date-segment/date-segment';
import { FieldDescription, FieldError, FieldLabel, FieldProps } from '../../atoms/field/field';
import Icon from '../../atoms/icon/icon';
import { Popover } from '../../atoms/popover/popover';
import { Pressable } from '../../atoms/pressable/pressable';
import { input, inputContainer } from '../../electrons/input';
import { listBoxTrigger } from '../../electrons/list-box';
import { Calendar } from '../calendar/calendar';

type IsDateUnavailableFn = (date: string) => boolean;

type OnChange = (value: string) => void;

type SlimDatePickerProps = Omit<
  DatePickerProps<DateValue>,
  | 'defaultValue'
  | 'value'
  | 'onChange'
  | 'isDateUnavailable'
  | 'minValue'
  | 'maxValue'
  | 'placeholderValue'
>;

interface _DatePickerProps extends SlimDatePickerProps, VariantProps<typeof input> {
  onCalendarHeaderChange?: (visibleRange: RangeValue<CalendarDate>) => void;
  name?: string;
  label: string;
  defaultValue?: string;
  value?: string;
  onChange?: OnChange;
  isDateUnavailable?: IsDateUnavailableFn;
  timezone: string;
  minValue?: string;
  maxValue?: string;
  placeholderValue?: string;
}

function _DatePicker({
  label,
  description,
  errorMessage,
  isDisabled,
  onCalendarHeaderChange,
  defaultValue,
  value,
  onChange,
  isDateUnavailable,
  timezone,
  minValue,
  maxValue,
  placeholderValue,
  name,
  granularity,
  ...props
}: _DatePickerProps & FieldProps) {
  const computedIsDateUnavailable = isFunction(isDateUnavailable)
    ? (date: DateValue) => {
        if (date instanceof ZonedDateTime) {
          return isDateUnavailable(date.toAbsoluteString());
        } else {
          return isDateUnavailable(date.toString());
        }
      }
    : undefined;

  const computedOnChange = isFunction(onChange)
    ? (date: DateValue | null) => {
        if (!isFunction(onChange)) return undefined;

        if (date instanceof ZonedDateTime) {
          onChange(date.toAbsoluteString());
        } else if (date) {
          onChange(toZoned(date, timezone).toAbsoluteString());
        }
      }
    : undefined;

  return (
    <DatePicker
      defaultValue={defaultValue ? parseAbsolute(defaultValue, timezone) : undefined}
      granularity={granularity}
      isDateUnavailable={computedIsDateUnavailable}
      isDisabled={isDisabled}
      isInvalid={!isEmpty(errorMessage)}
      maxValue={maxValue ? parseAbsolute(maxValue, timezone) : undefined}
      minValue={minValue ? parseAbsolute(minValue, timezone) : undefined}
      onChange={computedOnChange}
      placeholderValue={placeholderValue ? parseAbsolute(placeholderValue, timezone) : undefined}
      validationBehavior="aria"
      value={value ? parseAbsolute(value, timezone) : undefined}
      {...props}>
      {({ state }) => (
        <>
          {state.value && (
            <input
              name={name}
              type="hidden"
              value={
                state.value instanceof ZonedDateTime
                  ? state.value.toAbsoluteString()
                  : state.value.toString()
              }
            />
          )}
          {label && <FieldLabel>{label}</FieldLabel>}
          <Group className={inputContainer()}>
            <DateInput>{(segment) => <DateSegment segment={segment} />}</DateInput>
            <Pressable className={listBoxTrigger()}>
              <Icon className="h-5 w-5" name="calendar" />
            </Pressable>
          </Group>
          {errorMessage ? (
            <FieldError label={label}>{errorMessage}</FieldError>
          ) : (
            description && !isDisabled && <FieldDescription>{description}</FieldDescription>
          )}
          <Popover>
            <Dialog>
              <Calendar onCalendarHeaderChange={onCalendarHeaderChange} timezone={timezone} />
            </Dialog>
          </Popover>
        </>
      )}
    </DatePicker>
  );
}

export { _DatePicker as DatePicker };
