import { isFunction } from 'lodash-es';
import React from 'react';
import { DateSegment, DateSegmentProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

const _DateSegment = React.forwardRef<HTMLDivElement, DateSegmentProps>(
  ({ segment, className, ...props }, ref) => {
    return (
      <DateSegment
        className={(bag) =>
          twMerge(
            'focus:bg-grey-100 text-grey-900 rac-disabled:text-grey-300 rac-placeholder-shown:font-normal rac-placeholder-shown:text-grey-500 data-hidden:text-grey-400 rounded px-1 py-0.5 tracking-widest focus:outline-none aria-hidden:p-0',
            isFunction(className) ? className(bag) : className,
          )
        }
        ref={ref}
        segment={segment}
        {...props}
      />
    );
  },
);

export { _DateSegment as DateSegment };
