import { isFunction } from 'lodash-es';
import React from 'react';
import { DateInput, DateInputProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

const _DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <DateInput
        className={(bag) =>
          twMerge(
            'flex items-center gap-x-0.5 px-2 py-1.5',
            bag.isFocusVisible && 'ring-grey-700',
            isFunction(className) ? className(bag) : className,
          )
        }
        ref={ref}
        {...props}>
        {children}
      </DateInput>
    );
  },
);

export { _DateInput as DateInput };
